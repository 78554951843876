import validateCommunityLanguageCode from "../helpers/validateCommunityLanguageCode";

/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part of state that this reducer is responsible for
 * */
function IS() {
    return {
        isLoading: false,
        notificationCount: 0,
        notifications: [],
        showLoginModal: false,
        systemMessage: {id: null},
        postsRelatedSidebar: [],
        postsTrendingSidebar: [],
        userFollowSuggestionsSidebar: [],
        topicsTrendingSidebar: [],
        articleListSidebar: [],
        relatedArticleSidebar: [],
        reportReasons: [],
        trackerSidebar: null,
        tapCallbackUrl: null,
        ifTapPollMode: false,
        tapPollCallbackUrl: null,
        tapPollChoiceId: null,
        sitemap: '',
        deviceType: 'desktop',
        country: 'in',
        locale: 'en',
        cfCountryCodes: '',
        isCrawler: false,
        isCorrectPasswordResetToken: true,
        passwordStatus: false,
        passwordStatusMessage: "",
        randomUserNumber: 1,
        headerMenu: {},
        userRoles: {}
    };
}

const INITIAL_STATE = IS();

export const appReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case 'SHOW_LOGIN_MODAL':
            return {
                ...state,
                showLoginModal: true
            }
        case 'HIDE_LOGIN_MODAL':
            return {
                ...state,
                showLoginModal: false
            }
        case 'COMPLETE_NOTIFICATION_COUNT_UPDATE':
            return {
                ...state,
                notificationCount: action.payload.notificationCount
            }
        case 'START_NOTIFICATIONS_FETCH':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_NOTIFICATIONS_FETCH':
            return {
                ...state,
                isLoading: false,
                notifications: action.payload.isReset ? action.payload.response : [...state.notifications, ...action.payload.response],
                notificationCount: 0
            }
        case 'RESET_APP_LOADER':
            return {
                ...state,
                isLoading: false
            }
        case 'ADD_SYSTEM_MESSAGE':
            return {
                ...state,
                isLoading: false,
                systemMessage: action.payload
            }
        case 'COMPLETE_RELATED_POSTS_SIDEBAR':
            return {
                    ...state,
                    isLoading: false,
                    postsRelatedSidebar: action.payload.response.recommended.posts ? action.payload.response.recommended.posts : [],
                    relatedArticleSidebar: action.payload.response.recommended.articles ? action.payload.response.recommended.articles : [],
                    postsTrendingSidebar: action.payload.response.trending && action.payload.response.trending.posts ? action.payload.response.trending.posts : []
                }
        case 'START_USER_FOLLOW_SUGGESTIONS_FETCH':
            return {
                    ...state,
                    // isLoading: true
                }
        case 'COMPLETE_USER_FOLLOW_SUGGESTIONS_FETCH':
            return {
                    ...state,
                    // isLoading: false,
                    userFollowSuggestionsSidebar: action.payload.response.suggested_users
                }
        case 'COMPLETE_TOPIC_TRENDING_LIST_FETCH':
            return {
                ...state,
                topicsTrendingSidebar: action.payload.response.groups
            }
        case 'START_FEEDBACK_SAVE':
            return {
                ...state,
                isLoading: true
            }
        case 'COMPLETE_FEEDBACK_SAVE':
            return {
                ...state,
                isLoading: false
            }
        case 'START_TAP_LOGIN':
            return {
                ...state,
                tapCallbackUrl: action.payload.callbackUrl
            }
        case 'COMPLETE_TAP_LOGIN':
            return {
                ...state,
                isLoading: false,
                tapCallbackUrl: null
            }
        case 'START_TAP_POLL_LOGIN':
            return {
                ...state,
                tapPollCallbackUrl: action.payload.callbackUrl,
                tapPollChoiceId: action.payload.frpId
            }
        case 'COMPLETE_TAP_POLL_LOGIN':
            return {
                ...state,
                isLoading: false,
                tapPollCallbackUrl: null,
                tapPollChoiceId: null
            }
        case 'COMPLETE_TRACKER_TODAY_SIDEBAR':
            return {
                ...state,
                isLoading: false,
                trackerSidebar: action.payload.response
            }
        case 'COMPLETE_TAP_ARTICLES_SIDEBAR':
            return {
                ...state,
                isLoading: false,
                articleListSidebar: action.payload.response.article
            }
        case 'COMPLETE_REPORT_REASONS_FETCH':
            return {
                ...state,
                reportReasons: action.payload.response
            }
        case 'UPDATE_TAP_POLL_MODE':
            return {
                ...state,
                ifTapPollMode: action.payload.value
            }
        case 'COMPLETE_SITEMAP_FETCH':
            return {
                ...state,
                sitemap: action.payload.response
            }
        case 'UPDATE_DEVICE_TYPE':
            return {
                ...state,
                deviceType: action.payload.deviceType
            }
        case 'SET_APP_PARAMS':
            const newObj = {...state};
            for(const k in action.payload) {
                newObj[k] = action.payload[k];
            }
            return newObj;
        case 'UNSET_APP_PARAMS':
            const updatedObj = {...state};
            for(const k in action.payload) {
                if(updatedObj[k]) delete updatedObj[k];
            }
            return updatedObj;
        case 'COMPLETE_PASSWORD_RESET_TOKEN':
            return {
                ...state,
                isCorrectPasswordResetToken: action.payload.status
            }
        case 'COMPLETE_PASSWORD_RESET':
            return {
                ...state,
                passwordStatus: action.payload.status,
                passwordStatusMessage: action.payload.message
            }
        case 'COMPLETE_FORGOT_PASSWORD':
            return {
                ...state,
                forgotPasswordStatus: action.payload.status,
                forgotPasswordMessage: action.payload.message
            }
        case 'COMPLETE_HEADER_MENU_FETCH':
            return {
                ...state,
                headerMenu: action.payload
            }
        case 'START_AUTHOR_ROLES_FETCH':
            return {
                ...state,
                userRoles: {
                    ...state.userRoles,
                    isLoading: true
                }
            }
        case 'COMPLETE_AUTHOR_ROLES_FETCH':
            return {
                ...state,
                userRoles: {
                    ...state.userRoles,
                    data: action.payload,
                    isLoading: false
                }
            }
        case 'LOCALE_UPDATE':
            return {
                ...state,
                locale: validateCommunityLanguageCode(action.payload) ? action.payload : 'en'
            }         
        default:
            return state
    }
};
