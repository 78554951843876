import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { filterTranslation } from '../../../helpers' 
import loginModalTr from "@tickled-media/web-components.tm-community-login-widget/translations.js";
import confirmWidgetTr from "@tickled-media/web-components.tm-confirm-widget/translations.js";
import emailLoginTr from "@tickled-media/web-components.tm-email-login-otp-widget/translations.js";
import forgotPasswordWidgetTr from "@tickled-media/web-components.tm-forgot-password-otp-widget/translations.js";
import verifyEmailWidgetTr from "@tickled-media/web-components.tm-verify-otp-widget/translations.js";

i18n
.use(LanguageDetector)
.use(Backend)
.init({
    whitelist: ['en', 'th', 'id', 'my', 'vn', 'ph'],
    fallbackLng: 'en',
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    // react i18next special options (optional)
    // react: {
    //     wait: false,
    //     bindI18n: 'languageChanged loaded',
    //     bindStore: 'added removed',
    //     nsMode: 'default'
    // },
    backend: {
        loadPath: '{{lng}}/{{ns}}',
        parse: (data) => data,
        ajax: loadLocales
    }
});

function loadLocales(url, options, callback) {    
    try {
        const locale = url.split('/')[0];
        const loginTr = filterTranslation(locale, loginModalTr);
        const confirmTr = filterTranslation(locale, confirmWidgetTr);
        const emailTr = filterTranslation(locale, emailLoginTr);
        const forgotPasswordTr = filterTranslation(locale, forgotPasswordWidgetTr);
        const verifyEmailTr = filterTranslation(locale, verifyEmailWidgetTr);
        const localeData = require('../locales/' + url + '.json');
        // const data = {...localeData, ...loginTr};
        const data = {...localeData, ...loginTr, ...confirmTr, ...emailTr, ...forgotPasswordTr, ...verifyEmailTr};
        callback(data, {status: '200'});
    } catch (e) {
        console.log(e);
        callback(null, {status: '404'});
    }
}

export default i18n;