import { generateAssetCdnUrl } from '@tickled-media/web-components.tm-helpers';
// import ADS from './ads';
//import REGION from './region';
import API from './api';

const socialLinks = {
    sg: {
        fb: "https://www.facebook.com/theasianparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent/"
    },
    ph: {
        fb: "https://www.facebook.com/filipinoparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent_ph/"
    },
    id: {
        fb: "https://www.facebook.com/indonesianparent",
        //twitter: "https://twitter.com/AsianParentID",
        youtube: "https://www.youtube.com/channel/UC9KUiZz6CDgADq3bRCdGh-w",
        insta: "https://www.instagram.com/theasianparent_id/"
    },
    my: {
        fb: "https://www.facebook.com/theAsianparentMY",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent_my/"
    },
    vn: {
        fb: "https://www.facebook.com/theAsianparentVietnam",
        youtube: "https://www.youtube.com/user/theasianparent",
        insta: "https://www.instagram.com/theasianparent_vn/"
    },
    th: {
        fb: "https://www.facebook.com/theAsianparentTH",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/channel/UCOrctFJ2TgoVIWZbjz2qgPQ",
        insta: "https://www.instagram.com/theasianparent_th/"
    },
    in: {
        fb: "https://www.facebook.com/indusparent",
        //twitter: "https://twitter.com/theAsianparent",
        youtube: "https://www.youtube.com/channel/UC6ahM0GIwQbZJSUWRnjMsGA/feed",
    },
    jp: {
    },
    tw: {
        fb: "https://www.facebook.com/theAsianparentTW"
    },
    hk: {
        fb: "https://www.facebook.com/theasianparentHK"
    } 
};

const regionObj = {
    region: "asia",
    keyword: "theAsianparent",
    plural: "Asian",
    s3Prefix: generateAssetCdnUrl("parenttown-prod/community/"),
    logoImage: "Community_logo.svg",
    footerLogoImage: "TAP+logo_white_text-27.png",
    staticAd1: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_1b_300x250.jpg"),
    staticAd2: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_2b_300x250.jpg"),
    staticAd3: generateAssetCdnUrl("parenttown-prod/community", "TAPApps_launch_3b_300x250.jpg")
};

const CONFIG = {
    development: {
        siteUrl: 'http://localhost:3000/',
        apiBase: 'https://staging.parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'STG',
        // fbAppId: '194760671166813',
        fbAppId: '1038004076364647', //1677574999137048
        googleClientId: '578851203267-hjmi0d5drv70gpd14upds2740l0r0hut.apps.googleusercontent.com',
        redisUrl: 'localhost',
        region: regionObj,
        bugsnagKey: ''
    },
    staging: {
        siteUrl: 'https://dev-community.theasianparent.com/',
        apiBase: 'https://staging.parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'STG',
        // fbAppId: '2087861241473027',
        fbAppId: '1038004076364647', //1677574999137048
        googleClientId: '856646768262-6mpc7b0l56r3rbrhvglo52t9jit6lb1b.apps.googleusercontent.com',
        redisUrl: 'gp2-redis-staging.theasianparent.com',
        region: regionObj,
        bugsnagKey: ''
    },
    production: {
        siteUrl: 'https://community.theasianparent.com/',
        apiBase: 'https://parenttown.com/api/v2/',
        s3ImgPath: generateAssetCdnUrl("parenttown-prod/community/"),
        port: 3000,
        api: API,
        socialLinks: socialLinks,
        ads: {},
        cookieKey: 'PRD',
        fbAppId: '1038004076364647', //africa = 502170860554628'
        googleClientId: '1004252111741-p7gbg8eb17fh9ua589tlk02dobjuc2fd.apps.googleusercontent.com', //africa = 917606148957-aroc0js4acp3fa0ta0dqvruojkincvdr.apps.googleusercontent.com
        redisUrl: 'community-redis.dq1lrs.0001.apse1.cache.amazonaws.com',
        region: regionObj,
        bugsnagKey: '91edee8de684e008a939d5c04a10be2a'
    }
};

export default CONFIG[process.env.REACT_APP_ENV];
