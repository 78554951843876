import { generateAssetCdnUrl } from '@tickled-media/web-components.tm-helpers';
import React, { Component } from 'react';
import config from './config';
import regionImage from './assets/singapore.png';
import axios from 'axios';
import './style.css';

const CONFIG = config[process.env.NODE_ENV];

class CalculatorComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            title: '',
            selectedArea: '',
            noOfKids: 0,
            myMonthlyIncome: 0,
            myPartnersMonthlyIncome: 0,
            otherMonthlyIncome: 0,
            babyBonus: 0,
            monthlyMortgagePayment: 0,
            monthlyCarInstallments: 0,
            monthlyPaymentInsurance: 0,
            monthlyHouseholdExpenses: 0,
            monthlyExpenses: 0,
            oneOffExpenses: 0,
            result: 0
        };
    }

    componentDidMount() {        
    }

    switchStep = (step) => {
        let view = null;
        //
        switch(step) {
            case 1:
            view = this.Step1();
            break;
            case 2:
            view = this.Step2();
            break;
            case 3:
            view = this.Step3();
            break;
            case 4:
            view = this.Step4();
            break;
            case 5:
            view = this.Step5();
            break;
            case 6:
            view = this.Step6Result();
            break;
            default:
            view = null;
        }

        return view;
    }

    onBack = () => {
        if(this.state.step != 1) this.setState({step: this.state.step - 1});        
    }

    validate = (currentStep) => {
        let validated = true;
        const {
            // title,
            selectedArea,
            // noOfKids,
            // myMonthlyIncome,
            // myPartnersMonthlyIncome,
            // otherMonthlyIncome,
            // babyBonus,
            // monthlyMortgagePayment,
            // monthlyCarInstallments,
            // monthlyPaymentInsurance,
            // monthlyHouseholdExpenses,
            // monthlyExpenses,
            // oneOffExpenses
        } = this.state;
        //validate
        switch(currentStep) {
            case 2:
            if(!selectedArea) validated = false;
            break;
            case 3:
            // if(!noOfKids) validated = false;
            break;
            case 4:
            // if(!myMonthlyIncome || !myPartnersMonthlyIncome || !otherMonthlyIncome) validated = false;
            break;
            case 5:
            // if(!monthlyMortgagePayment || !monthlyCarInstallments || !monthlyPaymentInsurance || !monthlyHouseholdExpenses || !monthlyExpenses || !oneOffExpenses) validated = false;
            break;
            default:
            validated = true;
        }

        return validated;
    }

    onNextStep = () => {        
        if(this.state.step != 6 && this.validate(this.state.step)) {
            if(this.state.step == 5) {
                const result = this.calculateResult();
                this.setState({result});
                //save in db
                const {
                    country='sg',
                    title,
                    selectedArea,
                    noOfKids,
                    myMonthlyIncome,
                    myPartnersMonthlyIncome,
                    otherMonthlyIncome,
                    babyBonus,
                    monthlyMortgagePayment,
                    monthlyCarInstallments,
                    monthlyPaymentInsurance,
                    monthlyHouseholdExpenses,
                    monthlyExpenses,
                    oneOffExpenses
                } = this.state;
                const fields = {
                    country,
                    title,
                    selectedArea,
                    noOfKids,
                    myMonthlyIncome,
                    myPartnersMonthlyIncome,
                    otherMonthlyIncome,
                    babyBonus,
                    monthlyMortgagePayment,
                    monthlyCarInstallments,
                    monthlyPaymentInsurance,
                    monthlyHouseholdExpenses,
                    monthlyExpenses,
                    oneOffExpenses,
                    result
                };
                //send
                axios.post(CONFIG['api'], fields)
                .then((xhrResponse) => {
                    console.log(xhrResponse);
                })
                .catch((error) => {
                    console.log('e', error);
                });
            }
            //change
            this.setState({step: this.state.step + 1});
        } else {
            alert("Kindly enter the correct data!");
        }
    }

    calculateBabyBonus = () => {
        let babyBonus = 0;
        const noOfKids = this.state.noOfKids;
        //
        if(noOfKids > 0 && noOfKids < 3) {
            babyBonus = 8000;
        } else if(noOfKids >= 3) {
            babyBonus = 10000;
        }
        this.setState({babyBonus});
    }

    calculateResult = () => {
        const { 
            myMonthlyIncome = 0,
            myPartnersMonthlyIncome =
             0,
            otherMonthlyIncome = 0,
            monthlyMortgagePayment = 0,
            monthlyCarInstallments = 0,
            monthlyPaymentInsurance = 0,
            monthlyHouseholdExpenses = 0,
            monthlyExpenses = 0,
            babyBonus = 0,
            oneOffExpenses = 0
        } = this.state;

        //calc
        const result = 
        (
            (
                (
                    ( myMonthlyIncome + myPartnersMonthlyIncome + otherMonthlyIncome)
                     - 
                    (monthlyMortgagePayment + monthlyCarInstallments + monthlyPaymentInsurance + monthlyHouseholdExpenses + monthlyExpenses)
                )
                    *
                12
            )            
            + babyBonus - oneOffExpenses
        ) / 12;

        return result;
    }

    // onShareClick = (socialType) => {
    //     // switch(socialType) {
    //     //     case 'facebook':            
    //     //     break;
    //     //     case 'google':
    //     //     break;
    //     // }
    //     // this.setState({result, step: 7});
    // }

    Step1 = () => {
        return (
            <div className="step1">
                <div className='header'>
                    <h2>Demographics</h2>
                    <h3><strong>Step 1</strong> of 6</h3>
                    <p>GENDER</p>
                </div>

                <div className='body'>
                    <h3>Hello! How can we address you?</h3>
                    <p>Choose one of the following</p>
                    <div className="gender-select">
                        <div className={this.state.title == 'Ms' ? 'deactive' : 0} onClick={() => this.setState({title: 'Mr'})}>
                            <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "mr.png")} alt="Mr" loading="lazy" />
                            <label>Mr</label>
                        </div>
                        <div className={this.state.title == 'Mr' ? 'deactive' : 0} onClick={() => this.setState({title: 'Ms'})}>
                            <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "ms.png")} alt="Ms" loading="lazy" />
                            <label>Ms</label>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }

    Step2 = () => {
        const onRegionChange = (e) => {
            this.setState({selectedArea: e.target.value});
        }

        /*const nameMap = {
            0: 'North Region',
            1: 'West Region',
            2: 'North East Region',
            3: 'Central Region',
            4: 'East Region'
        };*/

        // const MAP = {
        //     name: "image-map",
        //     areas: [
        //         { shape: "poly", _id: 0, coords: [222,96,254,47,228,10,196,0,180,8,146,27,100,23,79,38,103,56,97,85,112,109,118,85,144,85,156,110,161,89,176,90,189,93,199,93,212,98] },
        //         { shape: "poly", _id: 1, coords: [95,86,104,53,73,38,59,51,29,114,18,130,9,165,1,187,12,205,52,188,70,181,92,181,108,180,148,185,165,137,143,82,118,87,112,110] },
        //         { shape: "poly", _id: 2, coords: [272,120,262,146,202,133,158,109,157,89,220,97,234,77,254,52,277,61] },
        //         { shape: "poly", _id: 3, coords: [199,135,178,115,162,116,164,144,147,185,144,209,182,211,193,229,219,248,279,186,256,148,234,135] },
        //         { shape: "poly", _id: 4, coords: [295,49,270,79,270,124,260,148,281,191,347,168,419,165,405,116,374,78,350,100,327,88,306,68] }
        //     ]
        // }

        // let onZoneClick = (area) => {
        //     this.setState({selectedArea: nameMap[area['_id']]});
        // }

        return (
            <div className="step2">
                <div className='header'>
                    <h2>Demographics</h2>
                    <h3><strong>Step</strong> 2 of 6</h3>
                    <p>LOCATION</p>
                </div>
                
                <div className='body'>
                    <h3>Which region are you living in?</h3>
                    <p>Choose one of the following</p>

                    { this.state.selectedArea && <p>Selected: {this.state.selectedArea}</p> }

                    <div className="map-warp">
                        {/*<ImageMapper src={regionImage} map={MAP} width={500}
                            onLoad={() => {}}
                            onClick={onZoneClick}
                            onMouseEnter={area => {}}
                            onMouseLeave={area => {}}
                            onImageClick={evt => {}}
                        />*/}
                        <img src={regionImage} alt="region" loading="lazy"/>
                        <select name="selected_area" onChange={onRegionChange}>
                            <option value=''>select</option>
                            <option value='North Region'>North Region</option>
                            <option value='West Region'>West Region</option>
                            <option value='North East Region'>North East Region</option>
                            <option value='Central Region'>Central Region</option>
                            <option value='East Region'>East Region</option>
                        </select>
                    </div>
                </div>
            </div>            
        );
    }

    Step3 = () => {
        const onKidsChange = (noOfKids) => {
            noOfKids = noOfKids ? parseInt(noOfKids): "";
            this.setState({noOfKids}, () => this.calculateBabyBonus());
        }

        return (
            <div className="step3">
                <div className='header'>
                    <h2>Demographics</h2>
                    <h3><strong>Step 3</strong> of 6</h3>
                    <p>KIDS</p>
                </div>
                <div className="body">
                    <h3>How many kids do you have?</h3>
                    <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "calc-no-of-kids.png")} alt="kid" loading="lazy" />
                    <div className="form-group">
                        <label>Fill the box with numbers</label>
                        <input type="number"
                            name="no_of_kids"
                            min="0"
                            value={this.state.noOfKids} onChange={e => onKidsChange(e.target.value)}
                            required />
                    </div>
                    
                </div>
            </div>            
        );
    }

    Step4 = () => {
        const setVal = (key, val) => {
            val = val ? parseInt(val) : "";
            this.setState({[key]: val});
        }

        return (
            <div className="step4">
                <div className='header'>
                    <h2>Household Income & Expenses</h2>
                    <h3><strong>Step 4</strong> of 6</h3>
                    <p>Household Income</p>
                </div>
                
                <div className="body">
                    <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "house.png")} alt="house" loading="lazy" />

                    <div className="form-group">
                        <label htmlFor="" >My Monthly Income</label>
                        <input type="number" name="my_monthly_income" min="0" value={this.state.myMonthlyIncome} onChange={e => setVal('myMonthlyIncome', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >My Partner's Monthly Income</label>
                        <input type="number" name="my_partners_monthly_income" min="0" value={this.state.myPartnersMonthlyIncome} onChange={e => setVal('myPartnersMonthlyIncome', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Other Monthly Income Sources(Rent etc)</label>
                        <input type="number" name="other_monthly_income" min="0" value={this.state.otherMonthlyIncome} onChange={e => setVal('otherMonthlyIncome', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Baby Bonus</label>
                        <input type="number" name="baby_bonus" min="0" value={this.state.babyBonus} onChange={e => setVal('babyBonus', parseInt(e.target.value))} required />
                    </div>
                </div>
            </div>            
        );
    }

    Step5 = () => {
        const setVal = (key, val) => {
            val = val ? parseInt(val) : "";
            this.setState({[key]: val});
        }

        return (
            <div className="step4">
                <div className='header'>
                    <h2>Household Income & Expenses</h2>
                    <h3><strong>Step 5</strong> of 6</h3>
                    <p>Expenses</p>
                </div>

                <div className="body">
                    <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "wallet.png")} alt="house" loading="lazy" />

                    <div className="form-group">
                        <label htmlFor="" >Monthly Mortgage Payment/Rent</label>
                        <input type="number" name="monthly_mortgage_payment" min="0" value={this.state.monthlyMortgagePayment} onChange={e => setVal('monthlyMortgagePayment', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Monthly Car Installments/Public Transportation</label>
                        <input type="number" name="monthly_car_installments" min="0" value={this.state.monthlyCarInstallments} onChange={e => setVal('monthlyCarInstallments', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Monthly Payment Into Insurance/Savings</label>
                        <input type="number" name="monthly_payment_insurance" min="0" value={this.state.monthlyPaymentInsurance} onChange={e => setVal('monthlyPaymentInsurance', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Monthly Household Expenses(Utilities, Food, Helper etc)</label>
                        <input type="number" name="monthly_household_expenses" min="0" value={this.state.monthlyHouseholdExpenses} onChange={e => setVal('monthlyHouseholdExpenses', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >Monthly Expenses(Child Care, School, Milk etc)</label>
                        <input type="number" name="monthly_expenses" min="0" value={this.state.monthlyExpenses} onChange={e => setVal('monthlyExpenses', parseInt(e.target.value))} required />
                    </div>

                    <div className="form-group">
                        <label htmlFor="" >One-off Expenses(Crib, Car Seat, Hospital etc)</label>
                        <input type="number" name="one_off_expenses" min="0" value={this.state.oneOffExpenses} onChange={e => setVal('oneOffExpenses', parseInt(e.target.value))} required />
                    </div>
                </div>
            </div>            
        );
    }

    // Step6Share = () => {
    //     let domain = "https://www.asianmoneyguide.com/family-planning-calculator/";
    //     return (
    //         <div className="step6">
    //             <div className='header'>
    //                 <h2>CONGRATULATIONS!</h2>
    //                 <h3>SHARE TO SEE THE RESULT</h3>
                    
    //             </div>
    //             <div className="body">
    //                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>

    //                 <a className="facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${domain + '?utm_source=asianmoneyguide.com&utm_medium=facebook&utm_campaign=app-calculator'}`}
    //                     onClick={() => this.onShareClick('facebook')}>Facebook</a>
    //                 <br />
    //                 <a className="gmail" target="_blank" href={`https://plus.google.com/share?url=${domain + '?utm_source=asianmoneyguide.com&utm_medium=google&utm_campaign=app-calculator'}`}
    //                     onClick={() => this.onShareClick('google')}>Google</a>
    //             </div>        
    //         </div>            
    //     );
    // }

    Step6Result = () => {
        const result = this.state.result;
        let resultText = "";
        let links = [];
        console.log(result);
        const domain = "https://www.asianmoneyguide.com/family-planning-calculator/";
        //results
        if(result <= 1000) {
            resultText = 
            `Awww, you only have ${Math.round(result)} left every month. 
            Having children in Singapore is not cheap! 
            You might want to hold off on having any more kids at the moment. 
            But if you are planning on growing your family, here are some articles 
            on how to help you save more to be able to afford another child.`;
            links = [
                "https://www.asianmoneyguide.com/financial-advice-for-women-before-kids",
                "https://www.asianmoneyguide.com/how-to-create-a-budget-spreadsheet",
                "https://www.asianmoneyguide.com/money-saving-tips-for-housewives/",
                "https://www.asianmoneyguide.com/how-to-manage-your-money-better/"
            ];
        } else if(result <= 2500) {
            resultText = 
            `You're on the right track with ${Math.round(result)} left every month. 
            You can afford to have another child though it might be a little tight.
            While you might on the right track now, make sure
            you have every stage of their lives planned out! These articles might help!`;
            links = [
                "https://www.asianmoneyguide.com/child-development-account-singapore/",
                "https://www.asianmoneyguide.com/cost-of-preschool-in-singapore",
                "https://www.asianmoneyguide.com/cheapest-private-school-in-singapore/",
                "https://www.asianmoneyguide.com/financial-priorities-list"
            ];
        } else if(result > 2500) {
            resultText = 
            `Congratulations! You have ${Math.round(result)} left every month, which means you can afford to have
            more kids!
            Since you're a superstar with your finances, learn how to protect your 
            children's futures by securing their finances now. Start right away with these articles.`;
            links = [
                "https://www.asianmoneyguide.com/countries-with-free-university-education",
                "https://www.asianmoneyguide.com/what-is-a-trust/",
                "https://www.asianmoneyguide.com/better-insurance/",
                "https://www.asianmoneyguide.com/best-health-insurance-in-singapore"
            ];
        }

        return (
            <div className="step7">
                <div className='header'>
                    <h2>Result!</h2>
                    <h3><strong>Step 6</strong> of 6</h3>
                    <p>Congratulations!</p>
                </div>

                <div className="body">
                    <div>
                        <img src={generateAssetCdnUrl("tap-assets/family-planning-calculator", "result.jpeg")} alt="result" loading="lazy" />
                    </div>
                    <div className="result">
                        <div>{resultText}</div>

                        <div>
                            {
                                links.map((link, i) => {
                                    return <p key={i}>
                                        <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                                    </p>
                                })
                            }
                        </div>

                        <div className="share">
                            <p>SHARE</p>

                            <a className="facebook" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${domain + '?utm_source=asianmoneyguide.com&utm_medium=facebook&utm_campaign=app-calculator'}`}
                                onClick={() => this.onShareClick('facebook')}>Facebook</a>
                            <br />
                            <a className="gmail" target="_blank" rel="noopener noreferrer" href={`https://plus.google.com/share?url=${domain + '?utm_source=asianmoneyguide.com&utm_medium=google&utm_campaign=app-calculator'}`}
                                onClick={() => this.onShareClick('google')}>Google</a>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }

    render() {
        
        return (
            <div className="family-planning-calculator">
                <div className="main-form">                
                    {
                        this.switchStep(this.state.step)
                    }
                </div>                

                <div className="navigation-group">
                    { [1, 6].indexOf(this.state.step) == -1 && <button className="prev" onClick={this.onBack}>Back</button>}

                    { [6].indexOf(this.state.step) == -1 && <button className="next" onClick={this.onNextStep}>Next</button> }
                </div>                
            </div>
        );
    }

}

export default CalculatorComponent;
