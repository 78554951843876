const API = {
    // token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFkOGIwODZhNzBjN2YwNTBmNjkzYjBlMjQ3MDA3NTA5YzU0NmNjMWUyN2Y5MThkZTMyNDAwY2MyZjEyMjYxZDZmNGRmOTc0YmEyN2Q5OTcxIn0.eyJhdWQiOiIzIiwianRpIjoiYWQ4YjA4NmE3MGM3ZjA1MGY2OTNiMGUyNDcwMDc1MDljNTQ2Y2MxZTI3ZjkxOGRlMzI0MDBjYzJmMTIyNjFkNmY0ZGY5NzRiYTI3ZDk5NzEiLCJpYXQiOjE1MjM4ODQ3MjUsIm5iZiI6MTUyMzg4NDcyNSwiZXhwIjoxNTU1NDIwNzI1LCJzdWIiOiIzIiwic2NvcGVzIjpbIioiXX0.AdXI1wRtrLnnX5pv5nTKp8xRZzk8-kSCZBhDH-rpAmPq1fDi83eHMSFrz_RtvS0BHDeZUn7lu04PFeqJn-jHgsaeHw0CA0aRyhphM-6P3DgjOEQvgWyfNPGbuxYZMfGZVrwP5k71MafK0jrmzPaBXxKJI2Q9xynip2HeLR6bsrzXQBvuc3M2zz1W1fa0IwoYA_Tm1FhGCg9xG-HMwEJMiGSrV5fIHPu71RPb_Rqbf3A7H7N7hC3hyUzia2nRx-IvExjR3vQe7uQ_2tl2YFCGJ89emZWRhNkJKAU5IDT9zuH2fWFD_zR0iQOiLxA6LqdPT12Hjwn3Lpc5fAgKllvz8mFRYSKzbxZZdTEqPINEkGi1IpiJwcGpisnOQWtUlLT84HxRptmxTIP1QPkef302l-s3ymbDtkJ5vqDHDu2yOD03ghhr0O3LlfqK-u9TOqIcp9sNDPcDJ3q1s4hHiXdIDeLWtX5pPmi_Mn-bpfkQCAy92KxVyZwlCHWBlDY0BhuEBcPS89wCjo1wjTPdyuCTAbn-nPRpJITEhhEne8Kyhqx05cZiuJt3s59sls9qVxFWat3TuGl28qSkO7zm0RgH0QKR1iLKQxqbCCpzJTRd8U50vyDASuPIw-7mbfw6w5YOSm6xVYhJAd5Ur1qdnuH4vVCcKljU0RBCZw9OKQmTqFc',
    token: '',    
    login: {
        url: 'login',
        method: 'post'
    },
    feed: {
        url: 'web/feeds',
        method: 'get'
    },
    hashFeed: {
        url: '/hashtag/feeds',
        method: 'get'
    },
    unansweredFeedFetch: {
        url: 'unanswered',
        method: 'post'
    },
    feedFilters: {
        url: 'feed/filters',
        method: 'get'
    },
    postFetch: {
        url: 'question',
        method: 'get'
    },
    tapPollFetch: {
        url: 'poll',
        method: 'get'
    },
    questionSave: {
        url: 'question/save',
        method: 'post'
    },
    questionEdit: {
        url: 'question/edit',
        method: 'post'
    },
    questionDelete: {
        url: 'question/remove',
        method: 'post'
    },
    questionLike: {
        url: 'question/like',
        method: 'post'
    },
    questionUnlike: {
        url: 'question/unlike',
        method: 'post'
    },
    pollVote: {
        url: 'poll/vote',
        method: 'post'
    },
    questionReply: {
        url: 'answer/post',
        method: 'post'
    },
    questionFollow: {
        url: 'question/follow',
        method: 'post'
    },
    questionUnfollow: {
        url: 'question/unfollow',
        method: 'post'
    },
    blockUnblockUser: {
        url: 'user/block_unblock',
        method: 'post'
    },
    report: {
        url: 'report',
        method: 'post'
    },
    reportReasonsFetch: {
        url: 'report/reason',
        method: 'post'
    },
    replyCommentSave: {
        url: 'answer/comment/save',
        method: 'post'
    },
    replyLike: {
        url: 'answer/like',
        method: 'post'
    },
    replyDislike: {
        url: 'answer/dislike',
        method: 'post'
    },
    replyEdit: {
        url: 'answer/edit',
        method: 'post'
    },
    replyDelete: {
        url: 'answer/remove',
        method: 'post'
    },
    replyCommentDelete: {
        url: 'answer/comment/remove',
        method: 'post'
    },
    repliesFetch: {
        url: 'answers',
        method: 'post'
    },
    commentsFetch: {
        url: 'answer/comments',
        method: 'post'
    },
    boothHashtagFetch: {
        url: 'booth/get/hashtag',
        method: 'post'
    },
    memoryFetch: {
        url: 'booth/get/single',
        method: 'post'
    },
    memoryCommentSave: {
        url: 'booth/comment',
        method: 'post'
    },
    memoryCommentDelete: {
        url: 'booth/comment/remove',
        method: 'post'
    },
    memoryCaptionEdit: {
        url: 'booth/edit',
        method: 'post'
    },
    memoryDelete: {
        url: 'booth/remove',
        method: 'post'
    },
    memoryLike: {
        url: 'booth/like',
        method: 'post'
    },
    memoryUnlike: {
        url: 'booth/unlike',
        method: 'post'
    },
    memoryCommentsFetch: {
        url: 'booth/comment/get',
        method: 'post'
    },
    topicFeedFetch: {
        url: 'web/feeds/group',
        method: 'post'
    },
    topicListFetch: {
        url: 'groups/list',
        method: 'post'
    },
    topicFollow: {
        url: 'group/join',
        method: 'post'
    },
    topicUnfollow: {
        url: 'group/leave',
        method: 'post'
    },
    topicRelatedListFetch: {
        url: 'group/related',
        method: 'post'
    },
    topicSuggestionsFetch: {
        url: 'group/suggestions',
        method: 'post'
    },
    authorProfileFetch: {
        url: '/user/profile',
        method: 'post'
    },
    authorProfileUpdate: {
        url: 'web/user/update/profile',
        method: 'post'
    },
    authorProfileAndPostsFetch: {
        url: 'web/user/profile',
        method: 'post'
    },
    authorFollowAction: {
        url: 'user/follow',
        method: 'post'
    },
    authorQuestionsFetch: {
        url: 'user/questions',
        method: 'post'
    },
    authorRepliesFetch: {
        url: 'user/answers',
        method: 'post'
    },
    authorFollowersFetch: {
        url: 'user/followers',
        method: 'post'
    },
    authorFollowingFetch: {
        url: 'user/followings',
        method: 'post'
    },
    authorMemoriesFetch: {
        url: 'booth/user/get',
        method: 'post'
    },
    linkPreviewFetch: {
        url: 'parse/question',
        method: 'post'
    },
    search: {
        url: 'search',
        method: 'post'
    },
    searchV2: {
        url: 'search/v2',
        method: 'get'
    },
    onboardingInfoSave: {
        url: 'register/steps',
        method: 'post'
    },
    topicsSelectedRegister: {
        url: 'register/topics',
        method: 'post'
    },
    notificationsFetch: {
        url: 'notifications',
        method: 'post'
    },
    privacyContentFetch: {
        url: 'privacy',
        method: 'post'
    },
    trackerCalendarFetch: {
        url: 'tracker/calendar',
        method: 'post'
    },
    userFollowSuggestions: {
        url: 'user/follow/suggestions',
        method: 'post'
    },
    topicTrendingListFetch: {
        url: 'group/trending',
        method: 'post'
    },
    feedbackSave: {
        url: 'feedback/save',
        method: 'post'
    },
    trackerPregnancyDetailsFetch: {
        url: 'tracker/pregnancy',
        method: 'post'
    },
    trackerBabyDetailsFetch: {
        url: 'tracker/baby',
        method: 'post'
    },
    trackerTodayFetch: {
        url: 'tracker/today',
        method: 'post'
    },
    tapArticlesFetch: {
        url: 'article/feed',
        method: 'post'
    },
    contestListFetch: {
        url: 'contests',
        method: 'post'
    },
    contestDetailsFetch: {
        url: 'contest',
        method: 'post'
    },
    contestMoreFetch: {
        url: 'contest/more',
        method: 'post'
    },
    sitemapFetch: {
        url: 'mainSitemap',
        method: 'post'
    },
    passwordResetVerifyTokenEmail: {
        url: 'email/forgot/verifyToken',
        method: 'post'
    },
    passwordResetVerifyTokenMobile: {
        url: 'phone/forgot/verifyToken',
        method: 'post'
    },
    passwordResetEmail: {
        url: 'email/password/reset',
        method: 'post'
    },
    passwordResetMobile: {
        url: 'phone/password/reset',
        method: 'post'
    },
    emailCheck: {
        url: 'email/check',
        method: 'post'
    },
    unsubscribe: {
        url: 'unsubscribe',
        method: 'get'
    },
    videosFetch: {
        url: 'get/media/web',
        method: 'get'
    },
    rewardsListFetch: {
        url: 'loyalty/rewards/2',
        method: 'post'
    },
    rewardDetailsFetch: {
        url: 'reward',
        method: 'get'
    },
    covidLandingPageFetch: {
        url: '/covid/landing/',
        method: 'get'
    },
    CovidLoadMoreData: {
        url: '/covid/articles/',
        method: 'get'
    },
    CovidCategoryQnAData: {
        url: '/covid/category/qna/',
        method: 'get'        
    },
    covidAnswerFetch: {
        url: '/covid/qna/',
        method: 'get'
    },
    forgotPassword: {
        url: '/email/forgot',
        method: 'post'
    },
    foodLandingPage: {
        url: '/foodCategories',
        method: 'post'
    },
    foodListing: {
        url: '/foodCategories/items',
        method: 'post'
    },
    foodDetail: {
        url: '/foodCategories/item/detail',
        method: 'get'
    },
    recipeDetail: {
        url: '/recipes/detail',
        method: 'get'
    },
    recipesListing: {
        url: '/recipes/listing',
        method: 'get'
    },
    recipesCategory: {
        url: '/recipe/category/show_all',
        method: 'get'
    },
    recipes: {
        url: '/recipes/landing',
        method: 'get'
    },
    sitemapBase: {
        url: '/sitemap/',
        method: 'post'
    },
    authorRolesFetch: {
        url: '/roles',
        method: 'get'
    },
    contentHub: {
        url: 'ch/campaign/detail',
        method: 'get'
    },
    contentHubDetailPage: {
        url: 'ch/campaign/activity/detail',
        method: 'get'
    },
    authorArticle: {
        url: '/author',
        method : 'get'
    },
    trendingHashtag: {
        url: '/hashtag/trending',
        method: 'get'
    },
    suggestedHashtag: {
        url: '/hashtag/suggestions',
        method: 'post'
    },
    similarQuestions: {
        url: '/create/question/similar',
        method: 'get'
    },
    userBlockList: {
        url: '/user/contextual-block/list',
        method: 'get'
    },
    userSettings: {
        url: '/user/settings',
        method: 'post'
    },
    userSettingsUpdate: {
        url: '/user/settings/set',
        method: 'post'
    },
    configFetch: {
        url: '/config',
        method: 'get'
    },
    dataSharingInfo: {
        url: '/data-sharing-info',
        method: 'get'
    },
    confirmDataSharing: {
        url: '/user/settings/set',
        method: 'post'
    },
    rssFeed: {
        url: '/rss',
        method : 'get'
    }
};

export default API;